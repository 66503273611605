
import { of as observableOf,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { NbAuthService, NbAuthJWTToken }  from '@nebular/auth';
import { AngularFireLiteFirestore } from 'angularfire-lite';

let counter = 0;

@Injectable()
export class UserService {

  private users = {
    nick: { name: 'Nick Jones', picture: 'assets/images/nick.png' },
    eva: { name: 'Eva Moor', picture: 'assets/images/eva.png' },
    jack: { name: 'Jack Williams', picture: 'assets/images/jack.png' },
    lee: { name: 'Lee Wong', picture: 'assets/images/lee.png' },
    alan: { name: 'Alan Thompson', picture: 'assets/images/alan.png' },
    kate: { name: 'Kate Martinez', picture: 'assets/images/kate.png' },
  };
  private user:any; 
  private userArray: any[];

  constructor( private authService : NbAuthService , private firestore : AngularFireLiteFirestore  ) {
     this.authService.onTokenChange()
      .subscribe((token: NbAuthJWTToken) => {
        if (token.isValid()) {
         let u = token.getPayload();  

         this.firestore.read('users/'+ u.user_id).subscribe((data) => {
            this.user = data;
           
         });
        }

      });
    
    //this.authService.isAuthenticated().subscribe(data=>{ console.log('user',data); })
    // this.userArray = Object.values(this.users);
  }

  getUsers(): Observable<any> {
    return observableOf(this.users);
  }

  getUserArray(): Observable<any[]> {
    return observableOf(this.userArray);
  }

  getUser(): Observable<any> {
    return observableOf(this.user);
  }
}
