import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { NbAuthService }  from '@nebular/auth';
//CanActivateChild,


@Injectable()
export class AuthGuard implements CanActivate {

	constructor( private authService : NbAuthService, private router : Router ) {
	}
	user;
	canActivate( route : ActivatedRouteSnapshot, state : RouterStateSnapshot ) {

		//if(this.authService.isAuthenticated()){		
		//if(this.authService.isAuthenticated().source.value.token){

			return this.authService.isAuthenticated().map(state =>{
				console.log('Auth State',state);


				if(state){
					//this.router.navigate(['/']);
					
					return true;
				}
				else{
					this.router.navigate(['auth/login']);
					return false;
				}
			});

		}
	}