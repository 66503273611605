import {
    Injectable,
    //ElementRef
} from '@angular/core';
import {
    BehaviorSubject
} from 'rxjs/BehaviorSubject';
import * as FileSaver from 'file-saver';
//import * as XLSX from 'xlsx';
//import { Workbook } from 'exceljs';
import * as ExcelJS from "exceljs/dist/exceljs";

import { Workbook } from "exceljs/dist/exceljs";


const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

declare const ExcelJS: any;


@Injectable()
export class DataService {

    //workbook: ExcelJS.Workbook;
    //worksheet: any;


    constructor() {}

    public generateExcel(data: any[] , title: string , header:any[]):void {


        //Create workbook and worksheet
        let workbook = new Workbook();
        let worksheet = workbook.addWorksheet('Sheet 1');

        	

        //Add Row and formatting
        let titleRow = worksheet.addRow([title]);
        titleRow.font = {
            //name: 'Comic Sans MS',
            //family: 4,
            size: 20,
            bold: true
        };
        //
        worksheet.addRow([]);

        // let subTitleRow = worksheet.addRow(['Date : ' + this.datePipe.transform(new Date(), 'medium')])

        //Add Header Row
        let headerRow = worksheet.addRow(header);
  
        //headerRow.Height = 30;
        // Cell Style : Fill and Border
        headerRow.eachCell((cell, number) => {
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: 'F67B00'
                }
            };
             cell.alignment = { vertical: 'middle', horizontal: 'center' };
             cell.font = {
    			name: "",
    			family: 4,
    			size: 11,
    			color: { argb: "ffffffff" },
    			underline: false,
    			bold: true,
  			};
            cell.border = {
                top: {
                    style: 'thin'
                },
                left: {
                    style: 'thin'
                },
                bottom: {
                    style: 'thin'
                },
                right: {
                    style: 'thin'
                }
            }
        });

         //worksheet.addRows(data);
        // Add Data and Conditional Formatting

        data.forEach(d => {
            let row = worksheet.addRow(d);
            let qty = row.getCell(5);
            let color = 'FF99FF99';
            if (+qty.value < 500) {
                color = 'FF9999'
            }
            qty.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: color
                }
            }
            qty.border = {
                top: {
                    style: 'thin'
                },
                left: {
                    style: 'thin'
                },
                bottom: {
                    style: 'thin'
                },
                right: {
                    style: 'thin'
                }
            }
        });
        worksheet.getColumn(1).width = 20;
        worksheet.getColumn(2).width = 20;


        worksheet.getColumn(3).width = 70;
        worksheet.getColumn(4).width = 30;
        worksheet.getColumn(5).width = 40;
        worksheet.getColumn(6).width = 30;
        worksheet.getColumn(7).width = 20;
        worksheet.getColumn(8).width = 30;
        worksheet.getColumn(9).width = 20;
        worksheet.getColumn(10).width = 20;
        worksheet.getColumn(11).width = 20;
        worksheet.getColumn(12).width = 20;
        worksheet.getColumn(13).width = 20;
        worksheet.getColumn(14).width = 20;
        worksheet.getColumn(15).width = 20;
        worksheet.getColumn(16).width = 20;
        worksheet.getColumn(17).width = 20;
        worksheet.getColumn(18).width = 20;
        worksheet.getColumn(19).width = 20;
        worksheet.getColumn(20).width = 20;
        worksheet.getColumn(21).width = 20;
        worksheet.getColumn(22).width = 80;

        worksheet.getRow(1).height = 20;
        worksheet.getRow(2).height = 20;
        worksheet.getRow(3).height = 25;

        worksheet.getColumn(1).alignment = { wrapText: true , vertical: 'middle'};
        worksheet.getColumn(2).alignment = { wrapText: true , horizontal: 'left' , vertical: 'middle'};
        worksheet.getColumn(4).alignment = { wrapText: true , vertical: 'middle' };
        worksheet.getColumn(5).alignment = { wrapText: true , vertical: 'middle'};
        worksheet.getColumn(6).alignment = { wrapText: true , vertical: 'middle'};
        worksheet.getColumn(7).alignment = { wrapText: true , vertical: 'middle'};
        worksheet.getColumn(8).alignment = { wrapText: true , vertical: 'middle'};
        worksheet.getColumn(9).alignment = { wrapText: true , vertical: 'middle'};
        worksheet.getColumn(10).alignment = { wrapText: true , vertical: 'middle'};
        worksheet.getColumn(11).alignment = { wrapText: true , vertical: 'middle'};
        worksheet.getColumn(12).alignment = { wrapText: true , vertical: 'middle'};
        worksheet.getColumn(13).alignment = { wrapText: true , vertical: 'middle'};
        worksheet.getColumn(14).alignment = { wrapText: true , vertical: 'middle'};
        worksheet.getColumn(15).alignment = { wrapText: true , vertical: 'middle'};
        worksheet.getColumn(16).alignment = { wrapText: true , vertical: 'middle'};
        worksheet.getColumn(17).alignment = { wrapText: true , vertical: 'middle'};
        worksheet.getColumn(18).alignment = { wrapText: true , vertical: 'middle'};
        worksheet.getColumn(19).alignment = { wrapText: true , vertical: 'middle'};
        worksheet.getColumn(20).alignment = { wrapText: true , vertical: 'middle'};
        worksheet.getColumn(21).alignment = { wrapText: true , vertical: 'middle'};
        worksheet.getColumn(22).alignment = { wrapText: true , vertical: 'middle'};
       
        worksheet.addRow([]);

        //Footer Row
        let footerRow = worksheet.addRow(['This is a system generated report.']);
        footerRow.getCell(1).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {
                argb: 'FFCCFFE5'
            }
        };
        footerRow.getCell(1).border = {
            top: {
                style: 'thin'
            },
            left: {
                style: 'thin'
            },
            bottom: {
                style: 'thin'
            },
            right: {
                style: 'thin'
            }
        };
        //Merge Cells
        worksheet.mergeCells(`A${footerRow.number}:V${footerRow.number}`);
        worksheet.mergeCells(`A1:V2}`);

        console.log('worksheet', worksheet);

        //this.saveAsExcelFile(excelBuffer, excelFileName);

        //Generate Excel File with given name
        workbook.xlsx.writeBuffer().then((data) => {
            let blob = new Blob([data], {
                type: EXCEL_TYPE
            });
            //fs.saveAs(blob, 'CarData.xlsx');
            //const data: Blob = new Blob([buffer], {
            // type: EXCEL_TYPE
            //});
            FileSaver.saveAs(blob, title + '_export_' + new Date().toLocaleDateString("en-US") + EXCEL_EXTENSION);
            //var overlay = this.elRef.nativeElement.closest('body').querySelector('.overlay-data-load').classList.add('hidden');

        });
    }

    /*
    	public exportAsExcelFile(json: any[], excelFileName: string): void {	
        
        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
        console.log('worksheet',worksheet);
        const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        //const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
        this.saveAsExcelFile(excelBuffer, excelFileName);
      }

      private saveAsExcelFile(buffer: any, fileName: string): void {
        const data: Blob = new Blob([buffer], {
          type: EXCEL_TYPE
        });
        FileSaver.saveAs(data, fileName + '_export_' + new Date() + EXCEL_EXTENSION);
      }
      */

    private clientId = new BehaviorSubject < string > ("default message");
    currentClientId = this.clientId.asObservable();

    private locationId = new BehaviorSubject < string > ("default message");
    currentlocationId = this.locationId.asObservable();

    private repId = new BehaviorSubject < string > ("default message");
    currentRepId = this.repId.asObservable();

    private jobStatusId = new BehaviorSubject < string > ("");
    currentStatusId = this.jobStatusId.asObservable();


    private artworkId = new BehaviorSubject < string > ("");
    currentArtworkId = this.artworkId.asObservable();

    private jobId = new BehaviorSubject < string > ("");
    currentJobId = this.jobId.asObservable();


    private jobTemplateId = new BehaviorSubject < string > ("");
    currentJobTemplateId = this.jobTemplateId.asObservable();

    private queue = new BehaviorSubject < string > ("");
    currentQueue = this.queue.asObservable();



    getClientId() {
        return this.clientId;
    }
    getLocationId() {
        return this.locationId;
    }
    getRepId() {
        return this.repId;
    }
    getJobStatusId() {
        return this.jobStatusId;
    }
    getArtworkId() {
        return this.artworkId;
    }
    getJobId() {
        return this.jobId;
    }
    getJobTemplateId() {
        return this.jobTemplateId;
    }
    getQueue() {
        return this.queue;
    }



    changeJobId(message: string) {
        this.jobId.next(message);
        //this.jobStatusId = message ;
    }
    changeArtworkId(message: string) {
        this.artworkId.next(message);
        //this.jobStatusId = message ;
    }
    changeRepId(message: string) {
        //this.repId = message ;
        this.repId.next(message);
    }
    changeJobStatusId(message: string) {
        this.jobStatusId.next(message);
        //this.jobStatusId = message ;
    }
    changeClientId(message: string) {
        //this.clientId = message ;
        this.clientId.next(message);
    }
    changeLocationId(message: string) {
        //this.locationId = message ;
        this.locationId.next(message);
    }

    changeJobTemplateId(message: string) {
        this.jobTemplateId.next(message);
        //this.jobStatusId = message ;
    }
    changeQueue(message: string) {
        this.queue.next(message);

    }


    isEmpty(value) {
        if (value == null || value.length == 0)
            return false;
        else
            return true;
    }

    getFormattedDate(d) {
        var date = new Date(d);

        var month = date.getMonth() + 1;
        var day = date.getDate();
        var hour = date.getHours();
        var min = date.getMinutes();
        var sec = date.getSeconds();

        let m = (month < 10 ? "0" : "") + month;
        let di = (day < 10 ? "0" : "") + day;
        let h = (hour < 10 ? "0" : "") + hour;
        let mi = (min < 10 ? "0" : "") + min;

        var str = date.getFullYear() + "-" + m + "-" + di + "T" + h + ":" + mi;

        /*alert(str);*/

        return str;
    }

}

/*
    this.data.currentMessage.subscribe(message => {
      console.log('hello',message);
    });
*/