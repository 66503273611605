import { Component, Input, OnInit ,  Inject , ViewChild,ElementRef} from '@angular/core';
import { NbSidebarService, NbSearchService ,NbMenuService} from '@nebular/theme';
import { UserService } from '../../../@core/data/users.service';
import { AnalyticsService } from '../../../@core/utils/analytics.service';
import { NbAuthService, NbAuthJWTToken }  from '@nebular/auth';
import { AngularFireLiteFirestore } from 'angularfire-lite';
import { filter, map } from 'rxjs/operators';
import { ActivatedRoute, Router  } from '@angular/router';
import { environment } from '../../../../environments/environment';

//import { Subject } from 'rxjs/Subject';
//import { SearchService } from './search.service';


@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
  //providers: [SearchService]
})
export class HeaderComponent implements OnInit {

  //@ViewChild('menu') menu: ElementRef;
  @Input() position = 'normal';

  showMenu = false;

  user: any;
  value;
  userMenu = [ { title: 'Log out' }]; //{ title: 'Settings' },
 
  isAdmin = false;
  isFactory = true;

  search: any;

  results = [];
  //searchTerm$ = new Subject<string>();

  constructor(private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private userService: UserService,
    private analyticsService: AnalyticsService ,
    private authService : NbAuthService ,
    private firestore : AngularFireLiteFirestore,
    //private searchService: NbSearchService,
    private nbMenuService: NbMenuService,
    private router: Router,
    private elRef:ElementRef,
   // private searchService: SearchService
    ) {

    
     // .subscribe(results => {
      //  this.results = results.results;
    //  });
  }

  //console.log('algolia',environment.algolia);
  
  searchConfig = {
    apiKey: 'f84db77ec90b5d010369928c91ba52b1',
    appId: 'ZZNYAL3T8A',
    routing: true,
    indexName: 'job_search'
  }

  ngOnInit() {
    //this.userService.getUser()
      //.subscribe((u: any) => console.log('user',u));//this.user = user
      this.authService.onTokenChange().subscribe((token: NbAuthJWTToken) => {
        if (token.isValid()) {
         let u = token.getPayload();  
         this.firestore.read('users/'+ u.user_id).subscribe((data) => {
          
          if(data){

          if(data.constructor == Object){
            this.user = data;

          if(this.user.assignment == ""){
              this.isFactory = false;
             
          }
              

          this.firestore.read('roles/'+this.user.role.id).subscribe(r =>{
            if(r){
              if(r.constructor == Object){

                if(r.canManageTemplates || r.canManageCollections || r.canManageUsers  || r.canManageCollections )
                  this.isAdmin = true;

              }
            }
          });
          }
        }
        });
       }



     });

       


/*
      this.searchService.onSearchSubmit().subscribe((data: any) => {
        this.value = data.term;
      
        this.firestore.query('jobs').where('name','>=',this.value).on().subscribe(res =>{
          //console.log('hi balkini man ban' , res);
        });


      });
*/


      this.nbMenuService.onItemClick()
      .pipe(
        filter(({ tag }) => tag === 'user-context-menu'),
        map(({ item: { title } }) => title),
        )
      .subscribe(title => {
        if(title == 'Log out'){
          this.router.navigate(['/auth/logout']);
        }else{
          this.router.navigate(['/user-settings'])
        }
      });

    }

    toggleSidebar(): boolean {
      this.sidebarService.toggle(true, 'menu-sidebar');
      return false;
    }

    toggleSettings(): boolean {
      this.sidebarService.toggle(false, 'settings-sidebar');
      return false;
    }

    goToHome() {
      this.menuService.navigateHome();
    }

    searchTerm(term){
        let q = this.firestore.query('jobs');
        q.where('search','array-contains',term.toLowerCase());
      
        if (this.user.assignment.id){
                               
          q.where('factory.id', '==', this.user.assignment.id);
        }
        q.limit(5).on().subscribe(res=>{

          this.results = res;

       });
    }
    resetSearch(){
       this.results = [];
    }

    startSearch() {
      this.analyticsService.trackEvent('startSearch');
    }
    previewMenu(){
      this.showMenu = true;
    //console.log('hello',this.elRef.nativeElement.querySelector('.big-menu'));
    this.elRef.nativeElement.querySelector('.big-menu').classList.add('open');
  }

  closeMenu(){
    this.elRef.nativeElement.querySelector('.big-menu').classList.remove('open');
  }

  redirectTo(uri: string) {
    this.closeMenu();
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => { 
      this.router.navigate(['/clients']);
    });
  }

  goToLink(url: string){
    window.open("https://drive.google.com/drive/folders/1lUbbDvWMmE5UHJLQ8DOQobRtL2QEpDNP?usp=sharing", "_blank");
    this.elRef.nativeElement.querySelector('.big-menu').classList.remove('open');
  }

}
